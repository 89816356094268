import React, { useState, useEffect } from "react"
// import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import YouTube from "react-youtube"
import Modal from "react-modal"
// import scrollTo from "gatsby-plugin-smoothscroll"

import { ImgRatio, BackgroundImage } from "components/anti/utils/utils"
import { Button } from "components/anti/buttons/buttons"
// import { Card } from "components/anti/card/card"
import { FooterBrand } from "components/fostive/footer-brand/footer-brand"

import gsap from "gsap"

import icPlay from "assets/img/common/ic_play.png"

// import imgArrowDown from "assets/img/common/arrow_down.png"

// import imgSample169a from "assets/img/sample/img_sample_16-9-a.jpg"
// import imgSample169b from "assets/img/sample/img_sample_16-9-b.jpg"
// import imgSample169c from "assets/img/sample/img_sample_16-9-c.jpg"
// import imgSample169d from "assets/img/sample/img_sample_16-9-d.jpg"

export const Cover = ({
  variant,
  theme,
  breadcrumb,
  img,
  forwardImgRef, // custom props - Fostive
  imgMd,
  imgHeight,
  imgOverlay,
  label,
  title,
  titleClassName,
  text,
  textClassName,
  buttons,
  contentMaxWidth,
  className,
  children,
  scrollHint,
  scrollHintLink,
  videoType,
  videoSrc,
  videoAudio,
  sideDescription,
  titleCustom, // custom props - fostive
  youtubeSrc, // custom props - fostive
}) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const modalClick = arg => {
    switch (arg) {
      case "open":
        setIsOpen(true)
        break

      case "close":
        setIsOpen(false)
        break

      default:
        return null
    }
  }

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
    },
  }

  useEffect(() => {
    gsap.to(".cover-bg", {
      scale: 1,
      ease: "expo.out",
      duration: 3,
      delay: 0.25,
    })

    if (!!titleCustom) {
      gsap.from(".cover-title-animated", {
        y: -100,
        ease: "expo.inOut",
        duration: 3,
        delay: 2.7,
        stagger: { amount: 0.1 },
      })
      gsap.from(".vertical-line", {
        y: -125,
        ease: "expo.inOut",
        duration: 3,
        delay: 2.5,
        stagger: { amount: 0.1 },
      })
      gsap.from(".watch-full-btn", {
        y: -150,
        ease: "expo.inOut",
        duration: 3,
        delay: 2.3,
        stagger: { amount: 0.1 },
      })
    }
  }, [])

  return (
    <div className={`cover cover-${variant} ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        {videoType === "video" ? (
          <div className={`video-background ${imgHeight}`}>
            {/* prettier-ignore */}
            <video playsInline autoPlay muted={videoAudio} loop className={imgHeight} style={{ objectFit: "cover", width: "fit-content" }}>
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        ) : videoType === "video-youtube" ? (
          <div className="video-background">
            {/* prettier-ignore */}
            <YouTube videoId={videoSrc} opts={videoOptions} className={imgHeight} />
          </div>
        ) : videoType === "video-popup" ? (
          <div className="cover-video">
            {/* prettier-ignore */}
            <BackgroundImage Tag="div" fluid={img} className={`cover-bg ${imgHeight}`}>
              <div className={`bg-overlay bg-overlay-${imgOverlay}`}></div>
              <ImgRatio />
            </BackgroundImage>
            {/* prettier-ignore */}
            <div className={`vid-pulse`} type="button" onClick={() => modalClick("open")}>
              <div className="vid-play">
                <i className="far fa-play" />
              </div>
            </div>
            {/* prettier-ignore */}
            <Modal isOpen={modalIsOpen} onRequestClose={() => modalClick("close")} className="modal-centered modal-xl">
              <div className="video-background">
                {/* prettier-ignore */}
                <video playsInline autoPlay muted={videoAudio} loop className="w-100">
                  <source src={videoSrc} type="video/mp4" />
                </video>
              </div>
            </Modal>
          </div>
        ) : (
          <div ref={forwardImgRef}>
            {/* Image responsive for MD Up*/}
            <BackgroundImage
              Tag="div"
              fluid={img}
              className={`cover-bg ${imgHeight} d-block`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              <ImgRatio />
            </BackgroundImage>
            {/* <BackgroundImage
                    Tag="div"
                    fluid={imgMd ? imgMd : img}
                    className={`cover-bg ${imgHeight} d-none d-md-block`}
                  >
                    <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
                    <ImgRatio />
                  </BackgroundImage> */}
          </div>
        )}
      </div>
      {breadcrumb && <div className="breadcrumb-wrapper">{breadcrumb}</div>}
      <div className="cover-body">
        <div className="container">
          <div className={`cover-content ${contentMaxWidth}`}>
            {label && (
              <div className="overflow-hidden">
                <p className="cover-label">{label}</p>
              </div>
            )}
            {title && (
              <>
                {titleCustom === "home" ? (
                  <>
                    <div className="overflow-hidden">
                      <h1
                        className={`cover-title cover-title-animated ${titleClassName}`}
                      >
                        {title}
                      </h1>
                      {videoType == "video" && (
                        <>
                          <div className="vertical-line h-sm"></div>
                          <div className="watch-full-btn d-flex justify-content-center mt-3">
                            <a
                              onClick={() => modalClick("open")}
                              className="cursor-pointer text-white d-md-flex align-items-md-center d-none"
                            >
                              <span>Watch Full Video</span>
                              <img
                                src={icPlay}
                                width="24px"
                                className="mr-1"
                              />{" "}
                            </a>
                            <a
                              target="blank"
                              href={youtubeSrc}
                              className="cursor-pointer text-white d-md-none align-items-md-center d-flex"
                            >
                              <img src={icPlay} width="24px" className="mr-1" />{" "}
                              <span>Watch Full Video</span>
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="overflow-hidden">
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => modalClick("close")}
                      >
                        <div className="video-background">
                          <div className="video-youtube">
                            <iframe
                              width="100%"
                              style={{
                                border: "none",
                                padding: "0.5rem",
                              }}
                              src={youtubeSrc}
                            ></iframe>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </>
                ) : (
                  <div className="overflow-hidden">
                    <h1 className={`cover-title ${titleClassName}`}>{title}</h1>
                  </div>
                )}
              </>
            )}
            {text && (
              <div className="overflow-hidden">
                <p className={`cover-text ${textClassName}`}>{text}</p>
              </div>
            )}
            {buttons && (
              <div className="btn-group">
                {buttons.map((button, i) => {
                  return (
                    <div className="overflow-hidden" key={i}>
                      <Button
                        variant={button.variant || "primary"}
                        link={button.link.url}
                        className={button.className || ""}
                      >
                        {button.text}
                      </Button>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <FooterBrand />
      </div>

      {children}

      {/* {!!sideDescription && (
        <>
          {sideDescription === "about" ? (
            <div className="custom-sidebar">
              <div>
                <h2 className="mb-lg-5 mb-4">About</h2>
                <p>
                  Fostive Table is Fostive Visual latest project specializes in
                  food photogra- phy & videography. Fostive Table started
                  because we believe that every restaurant has its own unique
                  personality.
                </p>
              </div>
              <div>
                <FooterBrand mobile={true} />
              </div>
            </div>
          ) : sideDescription === "work" ? (
            <>
              <div className="custom-sidebar d-none d-sm-block">
                <div className="custom-menu-trigger">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/work/categories/commercial"
                        onMouseEnter={() => changeBackground("commercial")}
                      >
                        <h2>Commercial</h2>
                      </Link>
                      <p>
                        Fostive Table is Fostive Visual latest project
                        specializes in food photography & videography.
                      </p>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/work/categories/wedding"
                        onMouseEnter={() => changeBackground("wedding")}
                      >
                        <h2>Wedding</h2>
                      </Link>
                      <p>
                        Fostive Table is Fostive Visual latest project
                        specializes in food photography & videography.
                      </p>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/work/categories/food"
                        onMouseEnter={() => changeBackground("food")}
                      >
                        <h2>Food</h2>
                      </Link>
                      <p>
                        Fostive Table is Fostive Visual latest project
                        specializes in food photography & videography.
                      </p>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to="/categories/architecture"
                        onMouseEnter={() => changeBackground("architecture")}
                      >
                        <h2>Architecture</h2>
                      </Link>
                      <p>
                        Fostive Table is Fostive Visual latest project
                        specializes in food photography & videography.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : sideDescription === "contact" ? (
            <div>
              <div className="custom-sidebar d-none d-sm-block">
                <div>
                  <h2 className="mb-5">Contact</h2>
                  <div className="reach-us mb-4">
                    <h5>Reach Us</h5>
                    <div className="d-flex flex-column text-white-50">
                      <span>Ian +62 816-4835-959</span>
                      <span>Felix +62 877-8877-6817</span>
                      <span>contact@fostivevisual.com</span>
                    </div>
                  </div>
                  <div className="location">
                    <h5>Location</h5>
                    <p className="text-white-50">
                      Jl. Cipete Raya No.55H RT.6/RW.4, Jakarta Selatan
                    </p>
                  </div>
                </div>
              </div>
              <div className="contact-sm-wrapper d-block d-sm-none">
                <div>
                  <h2 className="mb-5">Contact</h2>
                  <div className="reach-us mb-4">
                    <h5>Reach Us</h5>
                    <div className="d-flex text-white-50 flex-column">
                      <span>Ian +62 816-4835-959</span>
                      <span>Felix +62 877-8877-6817</span>
                      <span>contact@fostivevisual.com</span>
                    </div>
                  </div>
                  <div className="location">
                    <h5>Location</h5>
                    <p className="text-white-50">
                      Jl. Cipete Raya No.55H RT.6/RW.4, Jakarta Selatan
                    </p>
                  </div>
                </div>
              </div>
              <FooterBrand mobile={true} />
            </div>
          ) : null}
        </>
      )} */}
    </div>
  )
}

Cover.propTypes = {
  variant: PropTypes.oneOf(["basic", "responsive", "fluid"]),
  theme: PropTypes.oneOf(["", "light", "dark"]),
  breadcrumb: PropTypes.element,
  img: PropTypes.any,
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.any, // Set cover overlay %
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.string),
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string,
  children: PropTypes.any,
  scrollHint: PropTypes.bool,
  scrollHintLink: PropTypes.string,
  videoType: PropTypes.oneOf(["", "video", "video-youtube", "video-popup"]),
  videoSrc: PropTypes.any,
  videoAudio: PropTypes.bool,
}

Cover.defaultProps = {
  variant: "basic",
  theme: "",
  breadcrumb: null,
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-400px h-xs-500px h-md-600px",
  imgOverlay: 0,
  titleClassName: "",
  textClassName: "",
  buttons: [],
  className: "",
  type: "images",
  videoType: "",
  videoSrc: "",
  videoAudio: false,
  scrollHint: false,
  scrollHintLink: "#nextSection",
}

// Sample of usage

// <Cover
//    videoType="video-popup"
//    videoSrc="mlHklH5VBtI"
//    videoSrc="https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
//    videoAudio={false}
// >

// Cover with content inside
//
// Usually used for cover within pages (not on the top of the page)
export const CoverContent = ({
  theme,
  img,
  imgHeight,
  imgOverlay,
  contentMaxWidth,
  className,
  children,
}) => {
  return (
    <div className={`cover ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        <BackgroundImage
          Tag="div"
          fluid={img}
          className={`cover-bg ${imgHeight}`}
        >
          <div className="cover-body position-relative py-main">
            <div className="container">
              <div className={`cover-content ${contentMaxWidth}`}>
                {children}
              </div>
            </div>
          </div>
          <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
          <ImgRatio />
        </BackgroundImage>
      </div>
    </div>
  )
}

CoverContent.propTypes = {
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  img: PropTypes.any, // Set cover background image
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

CoverContent.defaultProps = {
  theme: "",
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-auto",
  imgOverlay: 0,
  className: "",
}
